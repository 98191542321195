.E2-Table {
    width: 100%;
}

.E2-Table>thead>tr>td,
.E2-Table>thead>tr>th,
.E2-Table>tbody>tr>td,
.E2-Table>tbody>tr>th,
.E2-Table>tfoot>tr>td,
.E2-Table>tfoot>tr>th {
    text-align: left;
}

.E2-Table.E2-Table__CostSummary>tbody>tr>td:last-child,
.E2-Table.E2-Table__CostSummary>tbody>tr>th:last-child {
    text-align: right;
}

.E2-Fulfillment-DataTable-Container .Polaris-DataTable__Table {
    table-layout: fixed;
}

.E2-Fulfillment-DataTable-Container .Polaris-DataTable__Cell--total.Polaris-DataTable__Cell--numeric {
    text-align: left;
}

.E2-Fulfillment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(1) {
    width: 24%;
}

.E2-Fulfillment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(4) {
    width: 20%;
}

.E2-Fulfillment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(2),
.E2-Fulfillment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(3) {
    width: 28%;
}

.E2-Fulfillment-DataTable-Container .Polaris-DataTable__Cell {
    white-space: normal;
}

.E2-Consignment-DataTable-Container .Polaris-DataTable__Table {
    table-layout: fixed;
}

.E2-Consignment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(1),
.E2-Consignment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(2) {
    width: 8%;
}

.E2-Consignment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(4) {
    width: 12%;
}

.E2-Consignment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(3) {
    width: 21%;
}

.E2-Consignment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(5) {
    width: 18%;
}

.E2-Consignment-DataTable-Container .Polaris-DataTable__Table>thead>tr>th:nth-child(6) {
    width: 33%;
}

.E2-Consignment-DataTable-Container .Polaris-DataTable__Cell {
    white-space: normal;
}
/* .E2-Consignment-DataTable-Container {
    margin-left: -2rem;
    margin-right: -2rem;
    margin-bottom: -2rem;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
} */

[data-rbd-droppable-id] [data-rbd-draggable-id] + [data-rbd-draggable-id] > .Polaris-ResourceItem__ListItem {
    border-top: var(--p-border-width-1) solid var(--p-color-border-subdued);
}

[data-rbd-droppable-id] [data-rbd-draggable-id] + [data-rbd-draggable-id] > .Polaris-ResourceItem__ListItem:not(.Polaris-ResourceItem--hasBulkActions):not(.Polaris-ResourceItem--selectMode)::after {
    border-radius: var(--p-border-radius-05);
}

[data-rbd-droppable-id] [data-rbd-draggable-id] > .Polaris-ResourceItem__ListItem:not(.Polaris-ResourceItem--hasBulkActions):not(.Polaris-ResourceItem--selectMode) .Polaris-ResourceItem__ItemWrapper {
    border-radius: unset;
}

[data-rbd-droppable-id] [data-rbd-draggable-id]:last-of-type > .Polaris-ResourceItem__ListItem:not(.Polaris-ResourceItem--hasBulkActions):not(.Polaris-ResourceItem--selectMode) .Polaris-ResourceItem__ItemWrapper {
    border-radius: inherit;
}

[data-rbd-droppable-id] [data-rbd-draggable-id] > .Polaris-ResourceItem__ListItem:not(.Polaris-ResourceItem--hasBulkActions):not(.Polaris-ResourceItem--selectMode),
[data-rbd-droppable-id] [data-rbd-draggable-id] > .Polaris-ResourceItem__ListItem:not(.Polaris-ResourceItem--hasBulkActions):not(.Polaris-ResourceItem--selectMode).Polaris-ResourceItem--focused::after {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
}

[data-rbd-droppable-id] [data-rbd-draggable-id]:last-of-type > .Polaris-ResourceItem__ListItem:not(.Polaris-ResourceItem--hasBulkActions):not(.Polaris-ResourceItem--selectMode),
[data-rbd-droppable-id] [data-rbd-draggable-id]:last-of-type > .Polaris-ResourceItem__ListItem:not(.Polaris-ResourceItem--hasBulkActions):not(.Polaris-ResourceItem--selectMode).Polaris-ResourceItem--focused::after {
    border-bottom-left-radius: var(--p-border-radius-2);
    border-bottom-right-radius: var(--p-border-radius-2);
}